import React from "react";
import useBusinessPageData from "../hooks/useBusinessPageData";

import Btn from "../components/Btn";
import CoursePreviewBlock from "../components/CoursePreviewBlock";
import CTASimple from "../components/CTASimple";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/layout";
import { SectionTitleAlt } from "../components/section_title";
import SplitBox from "../components/SplitBox";

import tw from "tailwind.macro";
import { css } from "@emotion/core";
import { md } from "../styles/breakpoints";

const styles = {
  pgBG: tw`bg-grey-lightest overflow-hidden`,
  ctaBtn: css`
    @media (${md}) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  `,
};

const BusinessPage = ({
  intro,
  hero1,
  hero2,
  coursePreview,
  coursePreviewCTA,
  ctaBlock,
}) => (
  <Layout>
    <Header />
    <div css={styles.pgBG}>
      <SectionTitleAlt {...intro} />
      <SplitBox tall reverse {...hero1} />
      <SplitBox tall {...hero2} />
    </div>
    <CoursePreviewBlock {...coursePreview} hideCTA>
      {coursePreviewCTA && (
        <Btn css={styles.ctaBtn} center {...coursePreviewCTA} />
      )}
    </CoursePreviewBlock>
    <CTASimple
      grayBG
      {...ctaBlock}
      cta={{
        ...ctaBlock.cta,
        invert: true,
      }}
      cta2={{
        ...ctaBlock.cta2,
        invert: true,
      }}
    />
    <Footer />
  </Layout>
);

const BusinessPageWithData = (props) => (
  <BusinessPage {...useBusinessPageData()} {...props} />
);

export default BusinessPageWithData;
